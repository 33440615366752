import React from 'react';
import {santaTypesDefinitions} from 'santa-components';
import {SantaComponent} from '../SantaComponent/SantaComponent';
import {StylableButton} from './StylableButton';

export function createIconFromString(svgString) {
  return React.createElement('div', {
    dangerouslySetInnerHTML: {
      __html: svgString || ''
    }
  });
}

export class SantaStylableButton extends SantaComponent {
  render() {
    const {svgString, styleId, compData} = this.props;
    const {id, style: requiredStyle, 'data-angle': dataAngle, 'data-preview': dataPreview, ...rest} = this.getRequiredProps();
    const haspopup = compData.a11y ? compData.a11y.haspopup : null;
    const hasPageLink = () => compData.link && compData.link.type === 'PageLink' ? 'true' : haspopup;
    const innerButtonRequiredStyle = {position: 'absolute'};
    const buttonStyle = compData.stylableButtonStyle ? {...compData.stylableButtonStyle, ...innerButtonRequiredStyle} : innerButtonRequiredStyle;
    const pressed = compData.a11y ? compData.a11y.pressed : null;
    const expanded = compData.a11y ? compData.a11y.expanded : null;
    const tabindex = compData.a11y ? compData.a11y.tabindex : null;

    return (
      <div id={id} style={requiredStyle} data-angle={dataAngle} data-preview={dataPreview}>
        <StylableButton
          {...rest}
          id={`${id}-inner-button`}
          style={buttonStyle}
          label={compData.label}
          ariaPressed={pressed}
          ariaExpanded={expanded}
          tabindex={tabindex}
          ariaHaspopup={hasPageLink()}
          className={`${styleId}--root`}
          icon={createIconFromString(svgString)}
        />
      </div>
    );
  }
}

SantaStylableButton.displayName = 'StylableButton';
SantaStylableButton.stylable = true;

SantaStylableButton.propTypes = {
  ...SantaComponent.propTypes,
  svgString: santaTypesDefinitions.RawSvg.getRawSVG,
  renderedLink: santaTypesDefinitions.Link.renderedLink,
  handleAction: santaTypesDefinitions.Behaviors.handleAction
};
